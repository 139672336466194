import React, { Fragment, FunctionComponent, ReactNode, useContext } from 'react'
import styled, { css, useTheme } from 'styled-components'
import { Box } from '@wh-components/core/Box/Box'
import { Text } from '@wh-components/core/Text/Text'
import { ClientRoutingAnchorLink } from '@wh/common/chapter/components/AnchorLink/AnchorLink'
import { AdvertisingStateContext } from '@wh/common/digital-advertising/components/AdvertisingStateProvider/AdvertisingStateProvider'
import { IconType, SvgIconProps } from '@wh-components/icons/utilities/createSvgIcon'
import { ResponsiveValue, SystemValue } from '@wh-components/system'
import { Button } from '@wh-components/core/Button/Button'

export interface TabsProps {
    tabs: ReactNode
    height?: ResponsiveValue<SystemValue>
}

export const SearchEntryAreaTabs: FunctionComponent<TabsProps> = ({ height = { phone: 64, tablet: 56 }, tabs }) => {
    const advertisingState = useContext(AdvertisingStateContext)
    const theme = useTheme()
    const tabBackgroundInactive = advertisingState.pageModifications.backgroundColors?.['tab-inactive']
    const tabBorderInactive = advertisingState.pageModifications.borders?.['tab-inactive'] ?? theme.colors.palette.owl
    const tabBorderColorActive = advertisingState.pageModifications.borders?.['tab-active']

    return (
        <Box
            width="100%"
            height={height}
            display="flex"
            background={tabBackgroundInactive ?? theme.colors.palette.polarbear}
            boxShadow={{
                phone: `inset 0 1px 0 0 ${tabBorderInactive}`,
                tablet: `inset 0 1px 0 0 ${tabBorderInactive}, inset 1px 0 0 0 ${tabBorderInactive}, inset -1px 0 0 0 ${tabBorderInactive}`,
            }}
            borderBottom={`2px solid ${tabBorderColorActive ?? theme.colors.palette.primary.main}`}
            borderTopRadius={{ tablet: 'm' }}
            testId="ad-type-tabs-wrapper"
        >
            {tabs}
        </Box>
    )
}

export const HyphenatedTitle = styled.span`
    overflow-wrap: wrap;
    hyphens: manual;
`

const TabContainerButton = styled(Button)`
    display: flex;
    align-items: center;
    height: 100%;
    border-radius: 0;

    ${(p) => p.theme.media.only.phone} {
        flex-grow: 1;
        justify-content: center;
    }

    ${(p) => p.theme.media.tablet} {
        &:first-of-type {
            border-top-left-radius: ${(p) => p.theme.borderRadii.m}px;
        }
    }
`

const TabContainerLink = styled(ClientRoutingAnchorLink)`
    display: flex;
    align-items: center;

    ${(p) => p.theme.media.only.phone} {
        flex-grow: 1;
        justify-content: center;
    }

    ${(p) => p.theme.media.tablet} {
        &:first-of-type {
            border-top-left-radius: ${(p) => p.theme.borderRadii.m}px;
        }
    }
`

export const AdTypeIcon: FunctionComponent<{ Icon: IconType; active?: boolean }> = ({ Icon, active = false }) => {
    const theme = useTheme()
    const advertisingState = useContext(AdvertisingStateContext)
    const tabForegroundIconActive = advertisingState.pageModifications.foregroundColors?.['tab-active-icon']
    const tabForegroundIconInactive = advertisingState.pageModifications.foregroundColors?.['tab-inactive-icon']
    const tabForegroundIconHover = advertisingState.pageModifications.foregroundColors?.['tab-hover-icon']

    const iconColor = active
        ? (tabForegroundIconActive ?? theme.colors.semantic.foreground.white)
        : (tabForegroundIconInactive ?? theme.colors.palette.darkgrey)

    const iconHoverColor = active ? iconColor : (tabForegroundIconHover ?? theme.colors.palette.darkgrey)

    const iconProps: SvgIconProps = {
        color: iconColor,
        size: 'xlarge',
        'aria-hidden': true,
    }

    return (
        <AdTypeIconInner
            Icon={Icon}
            iconProps={iconProps}
            css={css`
                /* we need to use && instead of & because the latter would reference the static class in this case which does not work when the contained css rules are instance-dependent https://github.com/styled-components/styled-components/issues/3244#issuecomment-687676703 */
                ${TabContainerLink}:hover && {
                    color: ${iconHoverColor};
                }
                ${TabContainerButton}:hover && {
                    color: ${iconHoverColor};
                }
            `}
        />
    )
}

// workaround for css styling, since `css` is no real prop, but gets transformed to className by a babel plugin
const AdTypeIconInner: FunctionComponent<{ Icon: IconType; iconProps: SvgIconProps; className?: string }> = ({
    Icon,
    iconProps,
    className,
}) => React.createElement(Icon, { ...iconProps, className })

interface TabProps {
    active?: boolean
    href?: string
    Icon: ReactNode
    title: ReactNode
    counter?: string
    onClick?: () => Promise<void>
    testIdPrefix?: string
    fontSize?: ResponsiveValue<SystemValue>
}

const tabActiveCss = (backgroundColor: string, borderColor: string) => css`
    background: ${backgroundColor};
    border-top: 1px solid ${borderColor};
    border-left: 1px solid ${borderColor};
    border-right: 1px solid ${borderColor};
    border-color: ${borderColor};

    &:focus {
        border-radius: 0;
    }

    &:hover {
        background: ${backgroundColor} !important;
        border-color: ${borderColor} !important;
    }
`

const tabInactiveAndHoverCss = (hoverBackgroundColor: string, hoverBorderColor: string) => css`
    border: 1px solid transparent;
    font-weight: normal;
    background-color: transparent;

    &:hover {
        background: ${hoverBackgroundColor} !important;
        border: 1px solid ${hoverBorderColor} !important;
    }
`

export const Tab: FunctionComponent<TabProps> = ({ active = false, href, Icon, title, counter, onClick, testIdPrefix, fontSize }) => {
    const theme = useTheme()
    const advertisingState = useContext(AdvertisingStateContext)
    const tabBackgroundActive = advertisingState.pageModifications.backgroundColors?.['tab-active']
    const tabBackgroundHover = advertisingState.pageModifications.backgroundColors?.['tab-hover']
    const tabBorderColorActive = advertisingState.pageModifications.borders?.['tab-active']
    const tabBorderColorHover = advertisingState.pageModifications.borders?.['tab-hover']
    const containerCss = active
        ? tabActiveCss(tabBackgroundActive ?? theme.colors.palette.primary.main, tabBorderColorActive ?? 'transparent')
        : tabInactiveAndHoverCss(tabBackgroundHover ?? theme.colors.semantic.surface.container.high, tabBorderColorHover ?? 'transparent')

    if (!href) {
        return (
            <TabContainerButton paddingHorizontal={{ phone: 's', desktop: 'm' }} css={containerCss} onClick={onClick}>
                <TabContent active={active} Icon={Icon} title={title} counter={counter} testIdPrefix={testIdPrefix} fontSize={fontSize} />
            </TabContainerButton>
        )
    } else {
        return (
            <TabContainerLink
                type="anchor"
                href={href}
                scrollToTop={false}
                underline="none"
                paddingHorizontal={{ phone: 's', desktop: 'm' }}
                css={containerCss}
                onClick={onClick}
            >
                <TabContent active={active} Icon={Icon} title={title} counter={counter} testIdPrefix={testIdPrefix} fontSize={fontSize} />
            </TabContainerLink>
        )
    }
}

interface TabContentProps {
    active?: boolean
    Icon: ReactNode
    title: ReactNode
    counter?: string
    testIdPrefix?: string
    fontSize?: ResponsiveValue<SystemValue>
}

const TabContent: FunctionComponent<TabContentProps> = ({
    active,
    Icon,
    title,
    counter,
    testIdPrefix,
    fontSize = { phone: 'xs', tablet: 's' },
}) => {
    const theme = useTheme()
    const advertisingState = useContext(AdvertisingStateContext)
    const tabForegroundTitleActive = advertisingState.pageModifications.foregroundColors?.['tab-active-title']
    const tabForegroundTitleInactive = advertisingState.pageModifications.foregroundColors?.['tab-inactive-title']
    const tabForegroundTitleHover = advertisingState.pageModifications.foregroundColors?.['tab-hover-title']
    const tabForegroundCounterActive = advertisingState.pageModifications.foregroundColors?.['tab-active-counter']
    const tabForegroundCounterInactive = advertisingState.pageModifications.foregroundColors?.['tab-inactive-counter']
    const tabForegroundCounterHover = advertisingState.pageModifications.foregroundColors?.['tab-hover-counter']

    const titleColor = active
        ? (tabForegroundTitleActive ?? theme.colors.semantic.foreground.white)
        : (tabForegroundTitleInactive ?? theme.colors.palette.darkgrey)

    const titleHoverColor = active ? titleColor : (tabForegroundTitleHover ?? theme.colors.palette.darkgrey)

    const counterColor = active
        ? (tabForegroundCounterActive ?? theme.colors.semantic.foreground.white)
        : (tabForegroundCounterInactive ?? theme.colors.palette.elephant)

    const counterHoverColor = active ? counterColor : (tabForegroundCounterHover ?? theme.colors.palette.elephant)

    return (
        <Box display="flex" alignItems="center" flexDirection={{ phone: 'column', tablet: 'row' }} testId={`${testIdPrefix}-tab`}>
            {Icon}
            <Box display="flex" flexDirection="column" marginTop={{ phone: -4, tablet: 0 }} marginLeft={{ tablet: 'xs', desktop: 's' }}>
                <Text
                    fontSize={fontSize}
                    fontWeight={active ? 'bold' : undefined}
                    color={titleColor}
                    textAlign={{ phone: 'center', tablet: 'left' }}
                    css={css`
                        /* we need to use && instead of & because the latter would reference the static class in this case which does not work when the contained css rules are instance-dependent https://github.com/styled-components/styled-components/issues/3244#issuecomment-687676703 */
                        ${TabContainerLink}:hover && {
                            color: ${titleHoverColor};
                        }
                        ${TabContainerButton}:hover && {
                            color: ${titleHoverColor};
                        }
                    `}
                >
                    {title}
                </Text>
                {counter && (
                    <Text
                        fontSize="xs"
                        color={counterColor}
                        lineHeight={1}
                        display={{ phone: 'none', tablet: 'block' }}
                        css={css`
                            /* we need to use && instead of & because the latter would reference the static class in this case which does not work when the contained css rules are instance-dependent https://github.com/styled-components/styled-components/issues/3244#issuecomment-687676703 */
                            ${TabContainerLink}:hover && {
                                color: ${counterHoverColor};
                            }
                            ${TabContainerButton}:hover && {
                                color: ${counterHoverColor};
                            }
                        `}
                        testId={`${testIdPrefix}-tab-counter`}
                    >
                        {counter || <Fragment>&nbsp;</Fragment>}
                    </Text>
                )}
            </Box>
        </Box>
    )
}
